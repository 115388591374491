import { LandingPageButtonLink, LandingVariant, UserSettings } from '@alpine-iq/shared';

export const LandingVariants: Record<
	LandingVariant,
	{
		title: string;
		description: string;
		isEnabled: (brandSettings?: Partial<UserSettings>) => boolean;
	}
> = {
	modern: {
		title: 'Modern style',
		description: 'Drive more customers to engage with your intended CTA via a clean non-distracting design',
		isEnabled: (brandSettings?: Partial<UserSettings>) => {
			return brandSettings?.landingPage?.variant === 'modern' || !brandSettings?.landingPage?.variant;
		},
	},
	linkTree: {
		title: 'Linktree style',
		description: 'Use full width large buttons',
		isEnabled: (brandSettings?: Partial<UserSettings>) => {
			return brandSettings?.landingPage?.variant === 'linkTree';
		},
	},
};

export const LandingPageButtonLinks: Record<
	LandingPageButtonLink,
	{
		title: string;
		description: string;
	}
> = {
	shopOnline: {
		title: 'Shop Online',
		description: 'Link to your online store',
	},
	directions: {
		title: 'Directions',
		description: 'Link to your location on a map',
	},
	callUs: {
		title: 'Call Us',
		description: 'Link to a phone number',
	},
	memberWallet: {
		title: 'Member Wallet',
		description: 'Link to a member wallet page',
	},
};
