import React from 'react';

import { GDataFilter } from '../components/global/GTable';
import utils from '../helpers/utils';
import { GeoValue } from './campaigns';
import { ColumnType } from 'antd/lib/table';
import { MessageCardConfig } from '../pages/wallet/assets/walletStyles';

export interface Source {
	avatar: string;
	title: string;
	errors: string[];
	mid: number;
	usersSourced: number;
	usersSourcedLast24: number;
};

export interface Destination {
	avatar: string;
	title: string;
	// usersPushedLast24Raw: integrations.Contact[];
	errors: string[];
	usersPushed: number;
	usersSuppressed: number;
	usersPushedLast24: number;
	mid: number;
};

export interface MissionControl {
	nextSiftRunTime: string;
	destinations: Destination[];
	audienceIDs: string[];
	sources: Source[];
	recipeIDs: string[];
	personas: number;
	personasAnon: number;
	personasRestricted: number;
	audiences: number;
};

export interface LeakySnapshot {
	HighLevel: { [key: string]: LifecycleData };
	RetailerLevel: { [key: string]: LeakyLevels };
}

export interface LeakyLevels {
	uid: string;
	name: string;
	formatted: LifecycleFormatted;
	Data: { [key: string]: LifecycleData };
	StoreBreakdown: { [key: string]: { [key: string]: LifecycleData } };
}

export interface LifecycleFormatted {
	leakyBucketBar: any[]
	lineEarned: any[]
	lifecycleConfig: LifecycleConfig
}

export interface LifecycleConfig {
	lifecycle: string;
	Active: number;
	activeRev: number;
	activeCount: number;
	activeColor: string;
	Chilling: number;
	chillingRev: number;
	chillingCount: number;
	coolingColor: string;
	Absent: number;
	absentRev: number;
	absentCount: number;
	atRiskColor: string;
	'Highly Absent': number;
	highlyAbsentRev: number;
	highlyAbsentCount: number;
	highlyAtRiskColor: string;
	Gone: number;
	goneRev: number;
	goneCount: number;
	lostColor: string;
	totalCustomers: number;
	totalNew: number;
	totalLost: number;
	totalWinback: number;
	totalAtRisk: number;
	winbackRatio: string;
	winbackPercentage: number;
	totalRevenueEarned: number;
}

export interface LifecycleData {
	lifecycleDistribution: GeoValue[];
	daily: DailyLeak[];
}

export interface DailyLeak {
	date: string;
	new: number;
	atRisk: number;
	lost: number;
	winBack: number;
	revenue: number;
}

// /invite/signup ep body
export interface FreeSignupUser {
	token: string
	email: string
	password: string
	name: string
}

export interface OverrideContact {
	// [index: string]: string | number | boolean | undefined;
	firstName?: string;
	lastName?: string;
	favoriteStoreID?: number;
	homePhone?: string;
	mobilePhone?: string;
	email?: string;
	birthdate?: number;
	driversLicense?: string;
	matchKey?: string;
	optOut?: boolean;
	loyalty?: boolean;
	ts?: number; // set by backend
};

export interface SendGridDNSObj {
	valid: boolean;
	type: string;
	host: string;
	data: string;
};

export interface SendGridDNS {
	mail_cname: SendGridDNSObj;
	dkim1: SendGridDNSObj;
	dkim2: SendGridDNSObj;
};

export interface SendGridDomain {
	id: number;
	user_id: number;
	subdomain: string;
	domain: string;
	ips: string[];
	custom_spf: boolean;
	legacy: boolean;
	automatic_security: boolean;
	valid: boolean;
	custom_dkim_selector: string;
	dns: SendGridDNS;
};

export interface SendGridSender {
	id: number;
	nickname: string;
	from_email: string;
	from_name: string;
	reply_to: string;
	reply_to_name: string;
	address: string;
	address2: string;
	state: string;
	city: string;
	zip: string;
	country: string;
	verified: boolean;
	locked: boolean;
};

export interface SendGridUser {
	domain?: SendGridDomain;
	sender?: SendGridSender;
	created?: number;
	updated?: number;
};

export interface SendGridWarning {
	domain: string;
	warning: string;
};

export interface SendGridDomainAuthReq {
	domain: string;
	subdomain?: string;
	username?: string;
	ips?: string[];
	custom_spf?: boolean;
	automatic_security?: boolean;
	custom_dkim_selector?: string;
};

export interface Message {
	code: string;
	host: string;
	userID: string;
	campaignID: string;
	contactID: string;
	from: string;
	message: string;
	extra: string;
	SID?: string;
	body?: string;
	carrier?: string;
	cmpID?: string;
	contentType?: string;
	direction?: string;
	discountTemplate?: boolean;
	isBrowser: boolean;
	hungup: "callee" | "caller"
	isEmail: boolean;
	isMMS: boolean;
	isPush: boolean;
	mediaURL: string;
	platform: string;
	price: string;
	segments: number;
	sent: boolean;
	timestamp: number;
	to: string;
	twilioStatus: string;
	unshorty: string;
	errorMsg?: string;
	meta?: string
	title?: string
	publisherUserID?: string
	brandBillingID?: string
	errorCode?: number
	isAd?: boolean
	thirdParty?: boolean
	snailMail?: boolean
	trs?: boolean

	config?: MessageCardConfig
}

export type InboxMessage = Message & {
	mediaURL?: string;
	contentType?: string;
}

export interface TableColumn<T = any> extends ColumnType<T> {
	// align?: 'left' | 'right' | 'center'   // The specify which way that column is aligned		
	// className?: string   // The className of this column	string	-	
	// colSpan?: number   // Span of this column's title	number	-	
	// dataIndex?: string | string[]   // Display field of the data record, support nest path by string array	string | string[]	
	// defaultFilteredValue?: string[]	   // Default filtered values	string[]	
	// filterResetToDefaultFilteredValue?: boolean  // Click the reset button, whether to restore the default filter	boolean	false	
	// defaultSortOrder?: 'ascend' | 'descend'   // Default order of sorted values	ascend | descend	
	// ellipsis?: any   // The ellipsis cell content, not working with sorter and filters for now.
	// tableLayout?: any   // would be fixed when ellipsis is true or { showTitle?: boolean }	boolean | {showTitle?: boolean }	false	showTitle: 4.3.0
	// filterDropdown?: any   // Customized filter overlay	ReactNode | (props: FilterDropdownProps) => ReactNode	
	// filterDropdownOpen?: any   // Whether filterDropdown is visible	boolean	
	// filtered?: any   // Whether the dataSource is filtered	boolean	false	
	// filteredValue?: any   // Controlled filtered value, filter icon will highlight	string[]	
	// filterIcon?: any   // Customized filter icon	ReactNode | (filtered: boolean) => ReactNode	
	// filterMultiple?: any   // Whether multiple filters can be selected	boolean	true	
	// filterMode?: any   // To specify the filter interface	'menu' | 'tree'	'menu'	4.17.0
	// filterSearch?: any   // Whether to be searchable for filter menu	boolean | function(input, record):boolean	false	boolean:4.17.0 function:4.19.0
	// fixed?: 'left' | 'right'   // (IE not support) Set column to be fixed: true(same as left) 'left' 'right'	boolean | string	false	
	// responsive?: any   // The list of breakpoints at which to display this column. Always visible if not set.	Breakpoint[]	4.2.0
	// shouldCellUpdate?: any   // Control cell render logic	(record, prevRecord) => boolean	4.3.0
	// showSorterTooltip?: any   // If header show next sorter direction tooltip, override showSorterTooltip in table	boolean | Tooltip props	true	
	// sortDirections?: any   // Supported sort way, override sortDirections in Table, could be ascend, descend	Array	[ascend, descend]	
	// sortOrder?: any   // Order of sorted values: 'ascend' 'descend' false	boolean | string	
	// title?: any   // Title of this column	ReactNode | ({ sortOrder, sortColumn, filters }) => ReactNode	
	// width?: any   // Width of this column (width not working?)	string | number	
	// onCell?: any   // Set props on per cell	function(record, rowIndex)	
	// onFilter?: any   // Function that determines if the row is displayed when filtered	function(value, record) => boolean	
	// onFilterDropdownVisibleChange?: any   // Callback executed when filterDropdownVisible is changed	function(visible) {}	
	onHeaderCell?: any   // Set props on per header cell	function(column)

	key?: string   // Unique key of this column, you can ignore this prop if you've set a unique dataIndex	string	
	render?: (text: any, record: T, index: number) => React.ReactNode;  // Renderer of the table cell. The return value should be a ReactNode	function(text, record, index) {}	
	shown?: boolean // * added by us to filter cols
	position?: number // * added by us to pos cols
	sort?: boolean | string // * added by us to pos cols
	uplift?: boolean

	onFilter?: (value: string | number | boolean, record: T) => boolean;

	children?: TableColumn[]

	// ! used by GTable
	titleStyle?: React.CSSProperties
	dataFilter?: GDataFilter

	EXPAND_COLUMN?: boolean
}
// export const getColumn = (title: string | React.ReactNode, render: (record: any) => React.ReactNode | any, cfg?: TableColumn) => {
// 	cfg = { ...cfg }
// 	if (!cfg.key) cfg.key = utils.strify(title)
// 	return ({ title, render, ...cfg }) as any
// } 

export const getColumn = <T = any>(
	title: string | React.ReactNode,
	render: (record: T) => React.ReactNode | any,
	cfg?: TableColumn<T>
): TableColumn<T> => {
	cfg = { ...cfg };
	if (!cfg.key) cfg.key = utils.strify(title);
	return { title, render, ...cfg };
};


export function getColumns<T>(defs: Array<TableColumn<T>>): TableColumn<T>[] {
	return defs
}