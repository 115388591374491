export const Role = {
	// Admin types
	ADMIN_SUPERUSER: 'admin_superuser',
	ADMIN_DIRECTOR: 'admin_director',
	ADMIN_ACCOUNTMANAGER: 'admin_accountmanager',
	// User types
	OWNER: 'owner',
	OPERATOR: 'operator',
	MA: 'marketinganalyst',
	MARKETING: 'marketing',
	ANALYST: 'analyst',
	MANAGER: 'manager',
	BUDTENDER: 'budtender',
	FINANCIAL: 'financial'
} as const;
export type Role = typeof Role[keyof typeof Role];

export const RoleTitles = {
	[Role.ADMIN_SUPERUSER]: 'Admin Superuser',
	[Role.ADMIN_DIRECTOR]: 'Admin Director',
	[Role.ADMIN_ACCOUNTMANAGER]: 'Account Manager',
	[Role.OWNER]: 'Owner',
	[Role.OPERATOR]: 'Operator',
	[Role.MA]: 'Marketing Analyst',
	[Role.MARKETING]: 'Marketing',
	[Role.ANALYST]: 'Analyst',
	[Role.MANAGER]: 'Manager',
	[Role.BUDTENDER]: 'Staff',
	[Role.FINANCIAL]: 'Financial'
} as const;

export interface AclPolicy {
	role: string;
	domain: string;
	route: string;
	method: string;
	perm: Perm;
};

export type Perm = 'allow' | 'deny';
